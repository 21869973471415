<template>
  <v-layout row wrap v-if="getImport">
    <v-flex xs12 v-if="generatingPreview && (getImport.status === 'preview-generating' || getImport.status === 'new')">
      <h3>Generating Preview (Import Id: {{ getImport.id }})</h3>
      <v-progress-linear indeterminate />
    </v-flex>
    <v-flex xs12 v-else-if="getImport.status === 'preview-ready'">
      <preview-table :rows="getImport.previewLines" />
      <v-btn @click="beginValidation">Begin Validation</v-btn>
    </v-flex>

    <v-flex xs12 v-else-if="getImport.status === 'preview-errors'">
      <h4>Errors generating preview</h4>
      <table style="width: 100%">
        <tr>
          <td>Status</td>
          <td>Line Number</td>
          <td>Errors</td>
        </tr>
        <tr v-for="(item, index) in getImport.previewLines" v-bind:key="`pr-${index}`">
          <td>
            <v-icon v-if="item.hasErrors" color="warning">warning</v-icon>
            <v-icon v-else color="green">check</v-icon>
          </td>
          <td>{{ index + 2 }}</td>
          <td>
            <ul v-if="item.hasErrors">
              <li v-for="(e, ei) in item.errors" v-bind:key="`pr-${index}-${ei}`">
                {{ e.severity }}
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </v-flex>
    <v-flex v-else-if="generatingPreview === false">
      <h3>{{ msg }}</h3>
    </v-flex>
    <v-flex xs12 v-else>
      <h4>Import in unknown state</h4>
      {{ getImport }}
    </v-flex>
  </v-layout>
  <v-layout row wrap v-else>
    <v-flex xs12>
      <h3>Beginning Processing</h3>
      <v-progress-linear indeterminate />
    </v-flex>
  </v-layout>
</template>

<script>
import { MUT_SNACKBAR } from '../../store';
import { IMPORT_PREVIEW_GQL } from './ImportPreview.contants';
import PreviewTable from './PreviewTable';

export default {
  components: { PreviewTable },
  props: ['context', 'next'],
  data() {
    return {
      generatingPreview: false,
      shouldPoll: true,
      counter: 1,
      msg: '',
    };
  },
  methods: {
    async beginValidation() {
      console.log('beginValidation');
      console.log(this.getImport.status);
      if (this.getImport.status === 'preview-ready') {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          id: this.context.importId,
        };

        const resp = await this.$apollo.mutate({
          mutation: IMPORT_PREVIEW_GQL.MUTATION.validateImport,
          variables: vars,
        });
        if (resp.errors) {
          this.$store.commit(MUT_SNACKBAR, {
            color: 'error',
            message: resp.errors[0].message,
          });
        } else {
          this.next();
        }
      } else {
        console.log('Should not have tried to validate');
      }
    },
    reset(msg) {
      this.generatingPreview = false;
      this.stopPolling();
      this.showErrorSnackbar(msg);
    },
    stopPolling() {
      this.shouldPoll = false;
      this.$apollo.queries.getImport.skip = true;
    },
  },
  apollo: {
    getImport() {
      return {
        query: IMPORT_PREVIEW_GQL.QUERY.getImport,
        variables() {
          console.log('reloading ip');
          return {
            orgId: this.$store.state.currentOrg.id,
            id: this.context.importId,
          };
        },
        skip() {
          const res = this.context === null || this.context.importId === null || this.context.importId === undefined;
          console.log('skip: ', res);
          return res;
        },
        update(data) {
          console.log('update');
          const { status } = data.getImport;
          console.log('status: ', status);
          console.log('counter: ', this.counter);
          switch (status) {
            case 'new':
              this.generatingPreview = true;
              break;
            case 'preview-generating':
              this.counter++;
              break;
            case 'preview-errors':
            case 'preview-ready':
              this.stopPolling();
              break;
          }
          return data.getImport;
        },
        result(data) {
          console.log('result');
          if (this.counter > 120) {
            this.msg = 'Error Generating Preview';
            this.reset(this.msg);
          }
        },
        error(e) {
          console.log('error');
          console.log(e);
          this.msg = 'Error Generating Preview';
          this.reset(this.msg);
        },
        pollInterval: this.shouldPoll ? 5000 : null,
      };
    },
  },
};
</script>
