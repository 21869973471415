<template>
  <v-layout row wrap v-if="getImport">
    <v-flex xs12 v-if="getImport.status === 'preview-ready'">
      <h3>Queueing Validation</h3>
      <v-progress-linear indeterminate />
    </v-flex>
    <v-flex xs12 v-if="getImport.status === 'validating'">
      <h3>Validating</h3>
      <v-progress-linear
        :indeterminate="getImport.progress == null"
        :value="getImport.progress"
        height="25"
        class="tw-mb-1"
      >
      </v-progress-linear>
      <strong class="tw-text-center tw-w-full tw-block">{{
        getImport.progress == null ? 'Initializing...' : getImport.progress + '%'
      }}</strong>
    </v-flex>
    <v-flex xs12 v-else-if="getImport.status === 'ready-to-import'">
      <h3>
        <v-icon color="success">check</v-icon>
        Validation Succeeded
      </h3>
      <v-btn @click="beginImport">Run Import</v-btn>
    </v-flex>
    <v-flex xs12 v-else-if="getImport.status === 'validation-failed'">
      <h3>
        <v-icon color="warning">warning</v-icon>
        Validation Failed
      </h3>
      <table style="width: 100%">
        <tr>
          <td>Status</td>
          <td>Line Number</td>
          <td>Errors</td>
        </tr>
        <tr v-for="(item, index) in getImport.validationErrors" v-bind:key="`pr-${index}`">
          <td>
            <v-icon v-if="item.errors.length > 0" color="warning">warning</v-icon>
            <v-icon v-else color="green">check</v-icon>
          </td>
          <td>{{ item.index + 2 }}</td>
          <td>
            <ul v-if="item.errors.length > 0">
              <li v-for="(e, ei) in item.errors" v-bind:key="`pr-${index}-${ei}`">
                {{ e }}
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </v-flex>
    <v-flex xs12 v-else> Unknown state: {{ getImport }} </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: ['context', 'next'],
  data() {
    return {
      getImport: null,
      isImporting: false,
    };
  },
  apollo: {
    getImport: {
      query: gql`
        query getImport($orgId: ID!, $id: ID!) {
          getImport(orgId: $orgId, id: $id) {
            id
            status
            progress
            validationErrors {
              index
              errors
            }
          }
        }
      `,
      variables() {
        console.log('reloading validate import');
        return {
          orgId: this.$store.state.currentOrg.id,
          id: this.context.importId,
        };
      },
      skip() {
        const res = this.context.importId === undefined || this.context.importId === null;
        console.log('skipping get validate: ', res);
        return res;
      },
      pollInterval: 2500,
    },
  },
  methods: {
    async beginImport() {
      this.isValidating = true;
      // Get upload URL
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: this.context.importId,
      };

      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            runImport(orgId: $orgId, id: $id)
          }
        `,
        // Parameters
        variables: vars,
      });

      this.next();
    },
  },
};
</script>
