<template>
  <v-layout row wrap>
    <v-flex xs12 class="tw-flex tw-items-center">
      <new-import :refresh="refresh" v-if="checkScope(scopeLiterals.ImportCreate)" />
      <a
        href="https://docs.google.com/spreadsheets/d/1Y-ADZtWRSEffVdubNyog2im9HzoKnFsUgI8gJFzhVco/edit#gid=945001329"
        target="_blank"
        class="tw-inline-flex tw-items-center"
      >
        <v-icon color="teal lighten-1" class="tw-mr-1">description</v-icon>Download Import Template</a
      >
    </v-flex>
    <v-flex xs12 v-if="isLoading">
      <v-progress-linear color="accent" height="2" indeterminate></v-progress-linear>
    </v-flex>
    <v-flex v-else>
      <table style="width: 125%">
        <tr>
          <th class="text-xs-left">Status</th>
          <th class="text-xs-left">ID</th>
          <th class="text-xs-left">Created</th>
          <th class="text-xs-left">
            <div style="display: flex; align-items: center">
              Errors <tooltip-select tip="_importErrors" class="tw-ml-2"></tooltip-select>
            </div>
          </th>
        </tr>
        <template v-for="(item, index) in imports">
          <tr :key="`f-${index}`">
            <td>
              <v-icon v-if="item.status === 'running'" color="success" class="fa-spin">refresh</v-icon>
              <v-icon v-else-if="item.status === 'completed'" color="success">check</v-icon>
              <v-icon v-else-if="item.status === 'completed-with-errors'" color="warning">warning</v-icon>
              <v-icon v-else color="warning">notification_important</v-icon>
              <span
                v-if="item.status === 'running'"
                class="tw-w-16 tw-text-center tw-inline-block tw-align-top"
                style="line-height: 25px"
              >
                {{ item.progress == null ? 'Initializing...' : item.progress + '%' }}
              </span>
            </td>
            <td>{{ item.id }}</td>
            <td>{{ toFriendlyDate(item.createdMS) }}</td>
            <td>
              <ul>
                <li v-for="(e, ei) in item.importErrors" v-bind:key="`e-${index}-${ei}`">
                  {{ parseImportError(e) }}
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </table>
    </v-flex>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';

import NewImport from './NewImport';

export default {
  components: {
    NewImport,
  },
  data() {
    return {
      imports: [],
      isLoading: 0,
      // il: null
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$apollo.queries.imports.refetch();
      console.log('imports fetched: ', this.imports);
    },
    toFriendlyDate(date) {
      return moment(date).format();
    },
    parseImportError(error) {
      try {
        const errorObj = JSON.parse(error);
        return `${errorObj.component} - ${errorObj.error.message}`;
      } catch (e) {
        return error;
      }
    },
  },
  apollo: {
    imports: {
      query: gql`
        query imports($orgId: ID!) {
          imports(orgId: $orgId) {
            id
            status
            createdMS
            importErrors
            progress
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
      pollInterval: 10000,
    },
  },
};
</script>
