<template>
  <div style="overflow: scroll">
    <table>
      <tr>
        <th></th>
        <th>ID</th>
        <th>Blockchain Id</th>
        <th>Transaction Type</th>
        <th>Time</th>
        <th>Account Id</th>
        <th>Amount</th>
        <th>Amount Ticker</th>
        <th>Cost</th>
        <th>Cost Ticker</th>
        <th>Fee</th>
        <th>Fee Ticker</th>
        <th>Contact Id</th>
        <th>Category Id</th>
        <th>Remote Contact Id</th>
        <th>Memo</th>
      </tr>
      <tr v-for="(item, index) in rows" v-bind:key="`p-${index}`">
        <td style="width: 35px">
          <v-icon v-if="item.hasErrors" color="warning">warning</v-icon>
          <v-icon v-else color="success">check</v-icon>
        </td>
        <td>{{ item.data.id }}</td>
        <td class="narrow-row">{{ item.data.blockchainId }}</td>
        <td>{{ item.data.transactionType }}</td>
        <td>{{ item.data.timeISO }}</td>
        <td class="narrow-row">{{ item.data.accountId }}</td>
        <td>{{ item.data.amount }}</td>
        <td>{{ item.data.amountTicker }}</td>
        <td>{{ item.data.cost }}</td>
        <td>{{ item.data.costTicker }}</td>
        <td>{{ item.data.fee }}</td>
        <td>{{ item.data.feeTicker }}</td>
        <td class="narrow-row">{{ item.data.contactId }}</td>
        <td class="narrow-row">{{ item.data.categoryId }}</td>
        <td class="narrow-row">{{ item.data.remoteContactId }}</td>
        <td>{{ item.data.memo }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
/*
        id
        blockchainId
        transactionType
        timeISO
        accountId
        amount
        amountTicker
        cost
        costTicker
        fee
        feeTicker
        contactId
        categoryId
        remoteContactId
        memo
     */
export default {
  props: ['rows'],
};
</script>

<style>
td.narrow-row {
  max-width: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
