<template>
  <form enctype="multipart/form-data" novalidate>
    <h2>Upload CSV</h2>
    <div class="dropbox" v-if="!isUploading">
      <input
        ref="fileUpload"
        type="file"
        :class="`${disabled ? 'tw-opacity-50 tw-cursor-not-allowed tw-pointer-events-none' : ''}`"
        :name="uploadFieldName"
        @change="filesChange($event.target.name, $event.target.files)"
        accept="text/csv"
        class="input-file"
      />
      <p>
        Drag your file here to begin<br />
        or click to browse
      </p>
      <p v-if="isUploading">Uploading {{ fileCount }} files...</p>
      <p>
        <v-btn @click="upload" :disabled="uploadData === null">Upload</v-btn>
      </p>
    </div>
    <v-progress-circular v-else indeterminate></v-progress-circular>
  </form>
</template>
<script>
import gql from 'graphql-tag';

import { upload } from '../../services/fileUploadService';
export default {
  props: ['context', 'next'],
  data() {
    return {
      uploadFieldName: 'csvFile',
      isUploading: false,
      canUpload: false,
      uploadData: null,
      importId: null,
      msg: '',
    };
  },
  methods: {
    filesChange(fieldName, fileList) {
      if (fileList.length === 1) {
        this.uploadData = fileList[0];
      }
    },
    async upload() {
      this.isUploading = true;
      // Get upload URL
      const vars = {
        orgId: this.$store.state.currentOrg.id,
      };

      try {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!) {
              createImport(orgId: $orgId) {
                id
                fileUploadUrl
              }
            }
          `,
          // Parameters
          variables: vars,
        });

        if (res && res.data) {
          const uploadUrl = res.data.createImport.fileUploadUrl;
          this.msg = `uploadUrl: ${uploadUrl}`;
          console.log(this.msg);

          const complete = await upload(this.uploadData, uploadUrl);
          this.msg = `Upload Status: ${complete}`;
          console.log(this.msg);

          this.importId = res.data.createImport.id;
          this.isUploading = false;
          this.context.importId = res.data.createImport.id;
          this.next();
        }
      } catch (e) {
        this.msg = 'Error uploading file';
        console.log(e);
        this.reset(this.msg);
        // throw e;
      }
    },
    reset(msg) {
      this.showErrorSnackbar(msg);
      this.uploadData = null;
      this.isUploading = false;
      this.$refs.fileUpload = null;
    },
  },
};
</script>
