<template>
  <ba-dialog
    :show="dialog"
    v-on:show-dialog="dialog = true"
    btnIcon="add"
    btnName="New Import"
    btnTooltip="Create Import"
    title="New Import"
    :onHide="hideDialog"
  >
    <v-layout row wrap ma-1 v-if="dialog">
      <v-flex xs12>
        <v-stepper v-model="currentStep" vertical>
          <v-stepper-step :complete="currentStep > 1" step="1"> Upload a file </v-stepper-step>

          <v-stepper-content step="1">
            <upload-import v-if="currentStep === 1" ref="uploadImport" :next="next" :context="context" />
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 2" step="2"> Review Data </v-stepper-step>

          <v-stepper-content step="2">
            <import-preview v-if="currentStep === 2" :next="next" :context="context" />
          </v-stepper-content>

          <v-stepper-step :complete="currentStep > 3" step="3"> Validate Data </v-stepper-step>

          <v-stepper-content step="3">
            <validate-import v-if="currentStep === 3" :next="next" :context="context" />
          </v-stepper-content>
        </v-stepper>
      </v-flex>
    </v-layout>
  </ba-dialog>
</template>

<script>
import ImportPreview from './ImportPreview';
import UploadImport from './UploadImport';
import ValidateImport from './ValidateImport';

export default {
  props: ['refresh'],
  data() {
    return {
      dialog: false,
      currentStep: 1,
      context: {
        importId: null,
      },
    };
  },
  components: {
    UploadImport,
    ImportPreview,
    ValidateImport,
  },
  methods: {
    next() {
      if (this.currentStep === 1) {
        if (this.context.id === null) {
          throw new Error('Validation failed, id expected');
        }
        this.currentStep = 2;
      } else if (this.currentStep === 2) {
        this.currentStep = 3;
      } else if (this.currentStep === 3) {
        this.close();
      }
    },
    close() {
      this.dialog = false;
    },
    hideDialog() {
      this.currentStep = 1;
      this.context = { importId: null };
      if (this.refresh) {
        this.refresh();
      }
      console.log('resetting new import');
      // this.$refs.uploadImport.reset();
    },
  },
};
</script>
