import gql from 'graphql-tag';

export const IMPORT_PREVIEW_GQL = {
  QUERY: {
    getImport: gql`
      query getImport($orgId: ID!, $id: ID!) {
        getImport(orgId: $orgId, id: $id) {
          id
          status
          previewLines {
            hasErrors
            errors
            data {
              id
              blockchainId
              transactionType
              timeISO
              accountId
              amount
              amountTicker
              cost
              costTicker
              fee
              feeTicker
              contactId
              categoryId
              remoteContactId
              memo
            }
          }
        }
      }
    `,
  },
  MUTATION: {
    validateImport: gql`
      mutation ($orgId: ID!, $id: ID!) {
        validateImport(orgId: $orgId, id: $id)
      }
    `,
  },
};
